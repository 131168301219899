<template>
	<transition name="slide-up-down">
		<section class="institution-view" v-if="value">
			<div
				class="institution-inner"
				:style="{ padding: isDetailView && '0px' }"
			>
				<button
					role="button"
					class="close"
					@click="$emit('close')"
					aria-label="Close"
					v-if="!isDetailView"
				>
					<span class="el-icon-close"></span>
				</button>
				<div class="institution-cover">
					<img v-if="inst.image_url" :src="inst.image_url" />
				</div>

				<div class="institution-header">
					<div class="header-flex">
						<img class="header-logo" :src="inst.logo_url" />
						<div class="header-text">{{ inst.name }}</div>
					</div>
					<div class="header-background">
						<img :src="inst.image_url" />
					</div>
				</div>

				<h3 class="institution-title">
					Help us {{ inst.full_impact_statement }}
				</h3>

				<div
					class="institution-description"
					style="max-height: 100px; overflow-y: scroll"
				>
					{{ inst.description }}
				</div>
				<div
					class="institution-button"
					v-if="isDetailView"
					style="position: relative"
				>
					<el-tag
						type="success"
						v-if="is_active"
						style="font-size: 16px"
						><div
							class="roundup-row"
							style="justify-content: center; align-items: center"
						>
							<img
								src="@/assets/greenCheck.png"
								style="
									height: 25px;
									width: 25px;
									margin-right: 10px;
								"
							/>
							Currently Donating
						</div>
					</el-tag>
					<ui-button
						v-else
						@click="selectInstitution"
						:disabled="is_rejected"
						style="margin-bottom: 10px"
						>Activate Nonprofit</ui-button
					>
				</div>
				<div
					class="institution-button"
					v-else
					style="position: relative"
				>
					<ui-button @click="selectInstitution"
						>Select Nonprofit</ui-button
					>
				</div>
			</div>
		</section>
	</transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import UiButton from '../../ui/Button'

export default {
	name: 'institution-view',
	components: { UiButton },
	props: {
		isDetailView: {
			type: Boolean,
			default: false
		},
		value: Object,
		is_active: {
			type: Boolean,
			default: false
		},
		onSuccess: {
			type: Function,
			default: () => {}
		},
		is_rejected: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			inst: this.value
		}
	},
	computed: {
		...mapGetters(['merchant'])
	},
	methods: {
		...mapActions([
			'attachInstitution',
			'getInstitutionDetail',
			'getCurrentUser',
			'clearSearch'
		]),
		selectInstitution() {
			if (this.merchant.is_nonprofit) {
				this.$swal(
					'Error',
					'You have designated yourself as a nonprofit in our system. In order to change to a different organization, please chat in below or contact support@roundupapp.com.'
				)
				return
			}

			this.attachInstitution(this.inst.id)
				.then(() => {
					this.$swal({
						toast: true,
						position: 'top-end',
						timer: 3000,
						showConfirmButton: false,
						title: 'Success',
						text: `You are now donating to ${this.inst.name}`
					})

					this.$emit('attached')
					var evObj = document.createEvent('Events')
					evObj.initEvent('click', true, false)
					document.getElementById('modal-close').dispatchEvent(evObj)
				})
				.catch(error => {
					this.$swal('Error', error.response.data.message)
				})
		}
	},
	watch: {
		value(newValue) {
			this.inst = newValue
			this.getInstitutionDetail(newValue.id).then(data => {
				this.inst = data
			})
		}
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';

.institution-view {
	flex: 1;
	padding-top: 20px;
	max-width: 480px;
	padding-bottom: 20px;
	@media screen and (max-width: 480px) {
		padding-top: 20px;
	}

	.institution-inner {
		position: relative;
		height: 100%;
		top: 0px;
		border-radius: 20px;
		background: $white;
		width: 100%;
		padding: 30px 20px 90px;
		overflow: auto;
		margin: 0 auto;
		.institution-cover {
			position: relative;
			height: 260px;
			border-radius: 12px 12px 0px 0px;
			overflow: hidden;
			img {
				height: 100%;
				max-width: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				width: 100%;
			}
		}
		.institution-header {
			background: #000;
			position: relative;
			overflow: hidden;
			min-height: 80px;
			margin: -60px 0 0;
			border-radius: 0px 0px 12px 12px;
			.header-background {
				position: absolute;
				bottom: -20px;
				margin-left: -25px;
				margin-right: -25px;
				-webkit-filter: blur(10px);
				filter: blur(10px);
				opacity: 0.9;
				z-index: 0;
				img {
					height: 100%;
					max-width: 100%;
					-o-object-fit: cover;
					object-fit: cover;
					width: 100%;
				}
			}
			.header-flex {
				position: relative;
				z-index: 1;
				display: flex;
				padding: 20px;
				.header-logo {
					position: relative;
					z-index: 1;
					max-width: 40px;
					max-height: 40px;
					flex-shrink: 0;
					border-radius: 6px;
				}
				.header-text {
					flex-grow: 1;
					color: $white;
					padding-left: 15px;
					font-weight: 600;
					font-size: 17px;
					display: flex;
					align-items: center;
				}
			}
		}
		.institution-title {
			font-family: 'Bitter', serif;
		}
		.institution-button {
			width: 100%;
			padding: 20px;
			text-align: center;
			margin-bottom: 0px;
			padding-bottom: 0px;
			padding-top: 10px;
			button {
				max-width: 440px;
				margin: 0 auto;
			}
		}
	}
	.close {
		background: #fff;
		position: absolute;
		top: 5px;
		right: 5px;
		padding: 2px 1px 3px;
		text-align: right;
		color: #333;
		cursor: pointer;
		content: 'Close';
		text-transform: uppercase;
		font-weight: 600;
		font-size: 14px;
		border-width: 0px;
		border-radius: 8px;
		z-index: 999;
		span {
			font-size: 16px;
			vertical-align: middle;
		}
		&:hover {
			font-weight: 900;
		}
	}
}

.slide-up-down-enter {
	opacity: 0;
	backface-visibility: hidden;
	z-index: 1;
	.institution-inner {
		transform: translate(0, 100%);
	}
}
.slide-up-down-leave-active {
	opacity: 0;
	backface-visibility: hidden;
	z-index: 1;
	.institution-inner {
		transform: translate(0, 100%);
	}
}
.slide-up-down-enter-active,
.slide-up-down-leave-active,
.slide-up-down-enter-active .institution-inner,
.slide-up-down-leave-active .institution-inner {
	transition: all 250ms ease-in-out;
}
</style>
